import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Seo from '../components/seo';
import Image from '../components/image';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import validator from 'validator';
import { Person, LockOff } from 'akar-icons';

const LoginPage = () => {
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });
    const [data, setData] = useState({
        email: '',
        password: '',
    });
    const [dataClass, setDataClass] = useState({
        email: 'login__input',
        password: 'login__input',
    });

    const handleChange = (e) => {
        if (e) {
            setData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    };

    const handleLogin = (e) => {
        if (e) {
            e.preventDefault();

            let _error = false;

            if (data.email === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    email: 'login__input inputerror',
                }));

                _error = true;
            } else {
                if (validator.isEmail(data.email) === false) {
                    setDataClass((prevState) => ({
                        ...prevState,
                        email: 'login__input inputerror',
                    }));

                    setMessageSetting((prevState) => ({
                        ...prevState,
                        showMessage: true,
                        messageClass: 'dialog dialog--error',
                        messageContent: 'Please use a valid email address.',
                    }));

                    _error = true;
                }
            }

            if (data.password === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    password: 'login__input inputerror',
                }));

                _error = true;
            }

            if (_error === false) {
                CustomFetch(
                    'login',
                    'POST',
                    data,
                    function (result) {
                        if (result.error === '') {
                            localStorage.setItem(
                                'dpTicketToken',
                                result.access_token
                            );

                            if (
                                result?.user?.roles[0]?.name === 'Subcontractor'
                            ) {
                                navigate('/document');
                            } else {
                                navigate('/');
                            }
                        } else {
                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--error',
                                messageContent: String(result.error),
                            }));
                        }
                    },
                    function (error) {
                        setMessageSetting((prevState) => ({
                            ...prevState,
                            showMessage: true,
                            messageClass: 'dialog dialog--error',
                            messageContent: error,
                        }));
                    }
                );
            }
        }
    };

    useEffect(() => {
        CustomFetch(
            'checkAuthStatus',
            'POST',
            {},
            function (result) {
                if (result.user?.roles[0]?.name === 'Subcontractor') {
                    navigate('/document');
                } else {
                    navigate('/');
                }
            },
            function (error) {
                console.info(error);
            }
        );
    }, []);

    return (
        <div id="app">
            <Seo title="Login" description="Dawn Projects Ticket System" />
            <CustomMessage data={messageSetting} />
            <div className="landing">
                <div className="landing__title">
                    <Image src="dawn-projects-logo.svg" alt="Dawn Projects" />
                    <h1>Ticket System</h1>
                </div>
            </div>
            <div className="login">
                <form onSubmit={handleLogin}>
                    <div className="login__box">
                        <div className="formitem fw">
                            <label className="fi__label">
                                <input
                                    name="email"
                                    className={dataClass.email}
                                    value={data.email}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder=" "
                                />
                                <span className="fi__span">Email Address</span>
                                <Person size={24} />
                            </label>
                        </div>
                        <div className="formitem fw">
                            <label className="fi__label">
                                <input
                                    name="password"
                                    className={dataClass.password}
                                    value={data.password}
                                    onChange={handleChange}
                                    type="password"
                                    placeholder=" "
                                />
                                <span className="fi__span">Password</span>
                                <LockOff size={24} />
                            </label>
                        </div>
                        <div className="formitem fw">
                            <button type="subbmit" className="loginbtn">
                                Login
                            </button>
                        </div>

                        <div className="register">
                            <span>
                                Don't have an account?{' '}
                                <Link to="/register">Register</Link>
                            </span>
                        </div>
                        <div className="forgotten">
                            <span>
                                <Link to="/forgot-password-request">
                                    Forgot Password
                                </Link>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
